import { PageProps } from "gatsby"
import * as React from "react"
import useStoryblok from "../hooks/useStoryblok.hook"
import renderBloks from "../utils/renderBloks.util"
import getMetaData from "../utils/getMetaData.util"
import Layout from "../components/utils/Layout/Layout.component"
import "../styles/loading-preview.css"
// import StoryblokEntryTemplateContext from "../interfaces/Storyblok/StoryblokEntryTemplateContext.interface"

interface StoryBlokPreviewPageProps {}

const StoryBlokPreview: React.FC<PageProps<StoryBlokPreviewPageProps>> = ({
  pageContext,
  location,
}) => {
  const story = useStoryblok(null, location)

  if (!story) {
    return (
      <div className="py-40 flex justify-center">
        <div className="loading-spinner"></div>
      </div>
    )
  }

  // const content: any = story?.content

  // const context: StoryblokEntryTemplateContext = {
  //   name: story?.name,
  //   id: story?.id,
  //   full_slug: story?.full_slug,
  //   field_component: content.component,
  //   content: story?.content,
  //   first_published_at: story?.first_published_at,
  // }

  const blocks = renderBloks(story?.content?.body || [])
  const metaData = getMetaData(story?.content)

  return <>{<Layout metaData={metaData}>{blocks}</Layout>}</>
}

StoryBlokPreview.propTypes = {}

export default StoryBlokPreview
